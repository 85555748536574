<template>
  <div>
    <div class="mb-3">
      <textarea
        class="form-control editor"
        :class="{ 'is-invalid': hasError }"
        ref="editor"
        :id="inputId"
        :aria-describedby="helpTextId"
        :placeholder="placeholder"
        :value="value"
        v-autogrow
        @input="handleChange">
      </textarea>
      <small :id="inputId" class="form-text text-muted mb-3">
        Éditeur de texte
      </small>
    </div>
    <label class="text-muted">Aperçu:</label>
    <div class="p-2 preview" v-html="compiledMarkdown"></div>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  directives: {
    autogrow: TextareaAutogrowDirective
  },
  computed: {
    compiledMarkdown () {
      return this.value ? sanitizeHtml(marked(this.value, { breaks: true, gfm: true })) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  height: 38px;
  overflow: hidden;
  resize: none;
}
.preview {
  border: 1px solid #ced4da;
  min-height: 38px;
  cursor: not-allowed;
}
</style>
